<template>
  <div class="navbar">
    <Hamburger :class="{ active }" class="hidden" @click="active = !active" />

    <nav>
      <Go
        v-for="snippet of $store.state.snippets"
        :key="snippet.slug"
        :to="'/?q='+snippet.slug"
        v-text="snippet.title"
      />
    </nav>

  </div>
</template>

<script>
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Hamburger,
  },
  data: () => ({
    active: false,
  }),
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  width: var(--nav-width);
  height: 100vh;
  color: #aaaaaa;
  transition: transform .3s ease-in-out;
  overflow: hidden;
  transform: translateX(-15%);

  &::before {
    content: '';
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 110%;
    height: 100%;
    transform: translateX(-10%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), white 80%);
    filter: blur(2px);
    transition: transform .3s ease-in-out;
  }

  &:hover {
    color: black;
    transform: translateX(0);
    &::before {
      transform: translateX(100%);
    }
  }
}

nav {
  $border: 30px;
  padding-top: $border;
  padding-bottom: $border;
  padding-left: $border;
  a {
    display: block;
  }
}

</style>
