/* eslint-disable max-len */

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import 'long-press-event';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';

import store from './store';

import './outline';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// router
// --------------------------------

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: () => import(/* webpackChunkName: "List" */ './components/pages/List.vue') },
    { path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "404" */ './components/structure/NotFound.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    if (savedPosition) return savedPosition;
    return { top: 0, behavior: 'smooth' };
  },
});

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);
app.use(router);
app.use(store);
app.use(VueScrollTo);

window[atob('cGF0cmljaw==')] = atob('U3RpbGxoYXJ0IHN0eWxlZCBoYXJk');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
