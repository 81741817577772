<template>

  <div id="popup" />

  <Navigation />

  <Bookmark />

  <main>
    <router-view v-slot="{ Component }">
      <transition name="appear" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Bookmark from '@/components/structure/Bookmark.vue';

export default {
  components: { Navigation, Bookmark },
  mounted() {
    this.$store.dispatch('getSnippets');
  },
};
</script>
