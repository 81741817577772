<template>
  <div class="plate">
    <svg>
      <defs>
        <filter id="gooeyness">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2.2" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
            result="gooeyness"
          />
          <feComposite in="SourceGraphic" in2="gooeyness" operator="atop" />
        </filter>
      </defs>
    </svg>

    <svg
      class="burger"
      version="1.1"
      height="100"
      width="100"
      viewBox="0 0 100 100"
    >
      <path class="line line1" d="M 30,35 H 70 " />
      <path class="line line2" d="M 50,50 H 30 L 34,32" />
      <path class="line line3" d="M 50,50 H 70 L 66,68" />
      <path class="line line4" d="M 30,65 H 70 " />
    </svg>

    <svg
      class="x"
      version="1.1"
      height="100"
      width="100"
      viewBox="0 0 100 100"
    >
      <path class="line" d="M 34,32 L 66,68" />
      <path class="line" d="M 66,32 L 34,68" />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.plate {
  height: 80px;
  width: 80px;
  cursor: pointer;
  margin-right: -24px;
}

svg {
  height: 80px;
  position: absolute;
  width: 80px;
}

.burger {
  filter: url(#gooeyness);
}
.x {
  transform: scale(0);
  transition: transform 400ms;
}
.line {
  fill: none;
  stroke: currentColor;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 50%;
  transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms,
    transform 500ms 200ms;
}

.line {
  transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms,
    transform 400ms 100ms;
}
.line1 {
  stroke-dasharray: 40 40;
}
.line2 {
  stroke-dasharray: 21 39;
}
.line3 {
  stroke-dasharray: 21 39;
}
.line4 {
  stroke-dasharray: 40 40;
}
.x {
  transition: transform 400ms 50ms;
}

.active .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active .line1 {
  stroke-dasharray: 1 40;
  stroke-dashoffset: -33px;
}
.active .line2 {
  stroke-dasharray: 5 39;
  stroke-dashoffset: -37px;
}
.active .line3 {
  stroke-dasharray: 5 39;
  stroke-dashoffset: -37px;
}
.active .line4 {
  stroke-dasharray: 1 40;
  stroke-dashoffset: -33px;
}
.active .x {
  transform: scale(1);
  transition: transform 400ms 350ms;
  transition: transform 400ms 50ms;
}
</style>
