<template>
  <div class="Bookmark" :class="{ show: showNavbar }">
    <Go to="/" class="logo" @click="reset">
      <Logo />
    </Go>

    <div class="inner">
      <a v-if="!$store.state.auth" @click="login = true">Auth</a>
      <a v-if="$store.state.auth" @click="more = true">More</a>

      <a @click="swapTheme">{{ theme || "auto" }}</a>
    </div>
  </div>

  <teleport to="#popup">
    <transition name="appear">
      <Login v-if="login" @close="login = false" />
    </transition>
  </teleport>

  <teleport to="#popup">
    <transition name="appear">
      <Modal v-if="more" @close="more = false">
        <h2>Add</h2>
        <SnippetEdit @close="more = false" />
      </Modal>
    </transition>
  </teleport>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Logo from '@/assets/images/logo.svg';

const SnippetEdit = defineAsyncComponent(() => import('@/components/blocks/SnippetEdit.vue'));
const Login = defineAsyncComponent(() => import('@/components/utils/Login.vue'));
const Modal = defineAsyncComponent(() => import('@/components/utils/Modal.vue'));

export default {
  components: {
    Logo, Login, SnippetEdit, Modal,
  },
  data: () => ({
    login: false,
    more: false,
    theme: localStorage.getItem('theme'),

    showNavbar: true,
    lastScrollPosition: 0,
  }),

  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },

  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    reset() {
      this.$store.state.search = '';
    },
    swapTheme() {
      const currentTheme = localStorage.getItem('theme');
      if (currentTheme === 'dark') this.theme = 'lamp';
      else if (currentTheme === 'lamp') this.theme = '';
      else this.theme = 'dark';
      localStorage.setItem('theme', this.theme);
      window.setTheme();
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition < 0) return;

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) return;

      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },

  },
};
</script>

<style lang="scss" scoped>
.Bookmark {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  width: 70px;
  padding: 15px;
  padding-bottom: 0;
  background-color: var(--cod);
  color: white;
  text-align: center;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));

  .inner {
    height: 0;
    padding-top: 10px;
    overflow: hidden;
    transition: height 0.2s ease;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 35px solid var(--cod);
    transform: translateY(100%);
  }

  &.show {
    padding-bottom: 15px;
    .inner {
      height: 60px;
    }
  }

  @screen md {
    width: 90px;
    padding: 20px !important;

    .inner {
      height: auto !important;
      padding-top: 20px;
    }

    &::after {
      border-left: 45px solid transparent;
      border-right: 45px solid transparent;
      border-top: 45px solid var(--cod);
    }
  }
}

.logo {
  display: block;
  color: var(--sunflower);
}

a {
  display: block;
  padding: 2px 0;
  text-transform: capitalize;
}
</style>
