import { createStore, createLogger } from 'vuex';
import axios from 'axios';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({

  state: {
    API: 'https://docs.stillh.art/api.php',
    auth: null,
    snippets: [],
    search: '',
  },

  actions: {
    async getSnippets({ state, commit }) {
      const response = await axios.get(`${state.API}`);
      commit('SET_SNIPPETS', response.data);
    },
  },

  mutations: {
    SET_SNIPPETS(state, snippets) {
      state.snippets = snippets;
    },
  },

  strict: false,
  plugins: debug ? [createLogger()] : [],

});
