function appendStyle(css) {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  head.appendChild(style);

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

if (typeof window !== 'undefined') {
  appendStyle(`
    a:focus,
    input:focus,
    button:focus {
        outline: none!important;
    }
  `);

  document.body.addEventListener('keyup', (e) => {
    if (e.which === 9) { /* tab */
      document.body.classList.remove('no-focus-outline');
    }
  });

  document.body.classList.add('no-focus-outline');
}
